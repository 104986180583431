import React, { useEffect, useState } from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,Filler,ArcElement} from 'chart.js';
import { Line,Pie } from 'react-chartjs-2';
import emoji1 from "./images/1.svg"
import emoji2 from "./images/2.svg"
import emoji3 from "./images/3.svg"
import emoji4 from "./images/4.svg"
import emoji5 from "./images/5.svg"
import emoji6 from "./images/6.svg"
import './App.css';
import db from "./Firebase";
import {setDoc, doc, collection, getDocs, updateDoc, increment, serverTimestamp,getDoc,DocumentData} from 'firebase/firestore/lite';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { async } from '@firebase/util';
import { isFloat32Array } from 'util/types';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import GetSpeakText from './GetSpeakText';

const isWeekend = (date: Dayjs) => {
  const day = date.day();
  return day === 0 || day === 6;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "35%",
  // height: "50%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(230, 230, 230)",
    },
  },
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props,ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [pieDB,setPieDB] = useState<DocumentData>({});
  const [btnsState, setBtnsState] = useState(["outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","contained"])
  const [pieChartData, setPieChartData] = useState([0,0,0,0,0,0]) 
  const [selectDocument, setSelectDocument] = useState("#all") 
  const [calenderValue, calenderSetValue] = useState<Dayjs | null>(dayjs());
  const [modalOpen, setModalOpen] = React.useState(false);
  const modalHandleOpen = () => setModalOpen(true);
  const modalHandleClose = () => setModalOpen(false);

  const [appBarText, setAppBarText] = useState("全てのデータ"); 

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const menuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {setAnchorEl(event.currentTarget);};
  const menuHandleClose = () => {setAnchorEl(null);};

  const [lineChartBool, setLineChartBool] = useState(false);
  const [btnOpacity1, setBtnOpacity1] = useState(1.0);
  const imageStyle1 = {
    opacity: btnOpacity1
  }
  const [btnOpacity2, setBtnOpacity2] = useState(1.0);
  const imageStyle2 = {
    opacity: btnOpacity2
  }
  const [btnOpacity3, setBtnOpacity3] = useState(1.0);
  const imageStyle3 = {
    opacity: btnOpacity3
  }
  const [btnOpacity4, setBtnOpacity4] = useState(1.0);
  const imageStyle4= {
    opacity: btnOpacity4
  }
  const [btnOpacity5, setBtnOpacity5] = useState(1.0);
  const imageStyle5 = {
    opacity: btnOpacity5
  }
  const [btnOpacity6, setBtnOpacity6] = useState(1.0);
  const imageStyle6 = {
    opacity: btnOpacity6
  }

  const [openMsg, setOpenMsg] = useState(false);
  const handleCloseMsg = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMsg(false);
  };
  const [chartData, setChartData] = useState([[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0]]);

  const CreateLineChart = async(chartDocData:DocumentData) =>{
    // const lineDoc = await getDoc(doc(db, "all", docName));
    // const lineDocData = lineDoc.data();
    //[時間][インデックス]
    let dataList:number[][] = [[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0]];
    // if(chartDocData){
    for(let i=0; i<Object.keys(chartDocData).length;i++){
      let timeNum = Object.keys(chartDocData)[i].slice(0,2);
      let indexNum = Object.keys(chartDocData)[i].slice(-1);
      dataList[Number(timeNum)-9][Number(indexNum)-1] = chartDocData[Object.keys(chartDocData)[i]]
    }
    // }
    setChartData(dataList);
  }

  // const RoopCreateChart = () => {
  //   let nowDocName = selectDocument;
  //   CreateChart(nowDocName);
  //   console.log(selectDocument);
  // }

  const CreateChart = async(docName:string) => {
    const chartDoc = await getDoc(doc(db, "all", docName));
    const chartDocData = chartDoc.data();
    if(chartDocData){
      CreateLineChart(chartDocData);
      CreatePieChart(chartDocData);
    }
    else{
      console.log("該当なし")
      //リセット-------------------
      setBtnsState(["outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","contained"]);
      setPieChartData([0,0,0,0,0,0]);
      setPieDB({});
      setChartData([[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0]]);
    }
  }

  //発声
  const VoiceGet = () => {
    console.log("喋る")
    //事前に音声一覧を取得する
    let voices = [];
    let i= 0;
    if(window.speechSynthesis.onvoiceschanged==null){
      window.speechSynthesis.onvoiceschanged = function(){
        voices = window.speechSynthesis.getVoices();
        //音声一覧をコンソールログに出力する場合
        for(i=0;i<voices.length;i++){
          console.log(voices[i].name);
        }
      }
    }else{
      voices = window.speechSynthesis.getVoices();
      //音声一覧をコンソールログに出力する場合
      for(i=0;i<voices.length;i++){
        console.log(voices[i].name);
      }
    }
  }

  const Speak = (content:string,index:number) => {
    // 話す速度 0.0(遅い)～1.0(標準)～2.0(速い)
    // uttr.rate=1.0;
    // ピッチ（声の高さ） 0.0(低め)～1.0(標準)～2.0(高め)
    // uttr.pitch=1.0;
    // お話が完了したときの関数
    // uttr.onend=function(){
    //   //alert("お話終了");
    // }
    const uttr = new SpeechSynthesisUtterance(content);
    uttr.rate=1.0;
    uttr.pitch=1.0;

    if(index===1){
      uttr.rate=1.0;
      uttr.pitch=1.5;
    }
    else if(index===2){
      uttr.rate=1.0;
      uttr.pitch=1.5;
    }
    else if(index===3){
      uttr.rate=1.0;
      uttr.pitch=1.2;
    }
    else if(index===4){
      uttr.rate=1.1;
      uttr.pitch=1.2;
    }
    else if(index===5){
      uttr.rate=0.9;
      uttr.pitch=1.1;
    }
    else{
      uttr.rate=0.9;
      uttr.pitch=0.8;
    }

    //「イギリス人男性風の声質」のvoiceオブジェクトを取得
  var voice = speechSynthesis.getVoices().find(function(voice){
    // return voice.name === 'Kyoko';
    return voice.name === 'Google UK English Male';
  });

  // 取得できた場合のみ適用する
  if(voice){
    uttr.voice = voice;
  }
  
    //日本語の音声は、以下のような値から選べる。選ばない場合はデフォルト設定
    // uttr.voice=window.speechSynthesis.getVoices().filter(
    //   voice => voice.name==='Microsoft Haruka Desktop - Japanese' //FireFox,Chrome
    //   voice => voice.name==='Microsoft Nanami Online (Natural) - Japanese (Japan)' //Edgeのみ可
    //   voice => voice.name==='Microsoft Ayumi - Japanese (Japan)' //Edge,Chromeのみ可
    //   voice => voice.name==='Microsoft Haruka - Japanese (Japan)' //Edge,Chromeのみ可
    //   voice => voice.name==='Microsoft Ichiro - Japanese (Japan)' //Edge,Chromeのみ可
    //   voice => voice.name==='Microsoft Sayaka - Japanese (Japan)' //Edge,Chromeのみ可
    //   voice => voice.name==='Google 日本語' //Chromeのみ可
    // )[0];
    
  
    // window.speechSynthesis.cancel();
    // お話しする
    window.speechSynthesis.speak(uttr);
  }

  //初回のみ
  const [triger, setTriger] = useState(Math.random())
  useEffect(() => {
    console.log("初回実行");
    VoiceGet();
    CreateChart(selectDocument);
    setInterval(()=>setTriger(Math.random()), 1000*60*60*6);
    //setInterval(()=>setTriger(Math.random()), 1000*10);
  },[]);
  useEffect(() => {
    if(appBarText==="今日のデータ"){
      let today:Date = new Date();
      let year:number = today.getFullYear();
      let month:number = today.getMonth()+1;
      let month2:string = month<10 ? "0"+month.toString() : month.toString();
      let day:number = today.getDate();
      let day2:string = day<10 ? "0"+day.toString() : day.toString();
      let date:string = `${year}年${month2}月${day2}日`;
      setSelectDocument(date);
      CreateChart(date);
      
      // console.log(date+today.getMinutes())
    }
    else{
      CreateChart(selectDocument);
    }
  },[triger]);
  // useEffect(() => {
  //   console.log("初回実行");
  //   CreateChart(selectDocument);
  //   setInterval(()=>RoopCreateChart(), 1000*60*60*6);
  // },[]);
  //定期実行

  ChartJS.register(ArcElement,CategoryScale,LinearScale,PointElement,LineElement,Filler,Title,Tooltip,Legend);
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: {
          beginAtZero: true,
          stacked: true,
          min: 0,
          max: 100
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: true
      },
      title: {
        display: true,
        text: '時間帯ごとの絵文字の割合',
        position: "bottom" as const,
      },
    },
  };

  const labels = ['9:00', '10:00', "11:00", '12:00', '13:00', '14:00', '15:00', "16:00", "17:00", "18:00"];

  const SumList = (data:number[]) => {
    return data.reduce(function(sum, element){
      return sum + element;
    }, 0);
  }
  const data = {
    labels,
    datasets: [
      {
        label: '🥰',
        data: [chartData[0][0]/SumList(chartData[0])*100, chartData[1][0]/SumList(chartData[1])*100, chartData[2][0]/SumList(chartData[2])*100, chartData[3][0]/SumList(chartData[3])*100, chartData[4][0]/SumList(chartData[4])*100, chartData[5][0]/SumList(chartData[5])*100, chartData[6][0]/SumList(chartData[6])*100, chartData[7][0]/SumList(chartData[7])*100, chartData[8][0]/SumList(chartData[8])*100, chartData[9][0]/SumList(chartData[9])*100],
        borderColor: 'rgb(232, 51, 19)',
        backgroundColor: 'rgba(232, 51, 19, 0.9)',
        fill: true
      },
      {
        label: '😉',
        data: [chartData[0][1]/SumList(chartData[0])*100, chartData[1][1]/SumList(chartData[1])*100, chartData[2][1]/SumList(chartData[2])*100, chartData[3][1]/SumList(chartData[3])*100, chartData[4][1]/SumList(chartData[4])*100, chartData[5][1]/SumList(chartData[5])*100, chartData[6][1]/SumList(chartData[6])*100, chartData[7][1]/SumList(chartData[7])*100, chartData[8][1]/SumList(chartData[8])*100, chartData[9][1]/SumList(chartData[9])*100],
        borderColor: 'rgb(232, 143, 19)',
        backgroundColor: 'rgba(232, 143, 19, 0.9)',
        fill: true
      },
      {
        label: '🙂',
        data: [chartData[0][2]/SumList(chartData[0])*100, chartData[1][2]/SumList(chartData[1])*100, chartData[2][2]/SumList(chartData[2])*100, chartData[3][2]/SumList(chartData[3])*100, chartData[4][2]/SumList(chartData[4])*100, chartData[5][2]/SumList(chartData[5])*100, chartData[6][2]/SumList(chartData[6])*100, chartData[7][2]/SumList(chartData[7])*100, chartData[8][2]/SumList(chartData[8])*100, chartData[9][2]/SumList(chartData[9])*100],
        borderColor: 'rgb(225, 232, 19)',
        backgroundColor: 'rgba(225, 232, 19, 0.9)',
        fill: true
      },
      {
        label: '😐',
        data: [chartData[0][3]/SumList(chartData[0])*100, chartData[1][3]/SumList(chartData[1])*100, chartData[2][3]/SumList(chartData[2])*100, chartData[3][3]/SumList(chartData[3])*100, chartData[4][3]/SumList(chartData[4])*100, chartData[5][3]/SumList(chartData[5])*100, chartData[6][3]/SumList(chartData[6])*100, chartData[7][3]/SumList(chartData[7])*100, chartData[8][3]/SumList(chartData[8])*100, chartData[9][3]/SumList(chartData[9])*100],
        borderColor: 'rgb(19, 232, 40)',
        backgroundColor: 'rgba(19, 232, 40, 0.9)',
        fill: true
      },
      {
        label: '😔',
        data: [chartData[0][4]/SumList(chartData[0])*100, chartData[1][4]/SumList(chartData[1])*100, chartData[2][4]/SumList(chartData[2])*100, chartData[3][4]/SumList(chartData[3])*100, chartData[4][4]/SumList(chartData[4])*100, chartData[5][4]/SumList(chartData[5])*100, chartData[6][4]/SumList(chartData[6])*100, chartData[7][4]/SumList(chartData[7])*100, chartData[8][4]/SumList(chartData[8])*100, chartData[9][4]/SumList(chartData[9])*100],
        borderColor: 'rgb(35, 194, 252)',
        backgroundColor: 'rgba(35, 194, 252, 0.9)',
        fill: true
      },
      {
        label: '😰',
        data: [chartData[0][5]/SumList(chartData[0])*100, chartData[1][5]/SumList(chartData[1])*100, chartData[2][5]/SumList(chartData[2])*100, chartData[3][5]/SumList(chartData[3])*100, chartData[4][5]/SumList(chartData[4])*100, chartData[5][5]/SumList(chartData[5])*100, chartData[6][5]/SumList(chartData[6])*100, chartData[7][5]/SumList(chartData[7])*100, chartData[8][5]/SumList(chartData[8])*100, chartData[9][5]/SumList(chartData[9])*100],
        borderColor: 'rgb(57, 35, 252)',
        backgroundColor: 'rgba(57, 35, 252, 0.9)',
        fill: true
      },
    ],
  };

  const changeOpacity = (index:number) =>{
    setBtnOpacity1(0.3)
    setBtnOpacity2(0.3)
    setBtnOpacity3(0.3)
    setBtnOpacity4(0.3)
    setBtnOpacity5(0.3)
    setBtnOpacity6(0.3)
    if(index===1){
      setBtnOpacity1(0.99)
    }
    else if(index===2){
      setBtnOpacity2(0.99)
    }
    else if(index===3){
      setBtnOpacity3(0.99)
    }
    else if(index===4){
      setBtnOpacity4(0.99)
    }
    else if(index===5){
      setBtnOpacity5(0.99)
    }
    else{
      setBtnOpacity6(0.99)
    }
    
    setTimeout(() => {setBtnOpacity1(1.0)}, 2000)
    setTimeout(() => {setBtnOpacity2(1.0)}, 2000)
    setTimeout(() => {setBtnOpacity3(1.0)}, 2000)
    setTimeout(() => {setBtnOpacity4(1.0)}, 2000)
    setTimeout(() => {setBtnOpacity5(1.0)}, 2000)
    setTimeout(() => {setBtnOpacity6(1.0)}, 2000)
  }

  const SendData = async(index:number) => {
    changeOpacity(index)
    // let speakText = GetSpeakText(index)
    // Speak(speakText,index)
    // if(index===1){
    //   Speak("がんばっているきみがすきだよ",index);
    // }
    // else if(index===2){
    //   Speak("いいじゃん　その調子",index);
    // }
    // else if(index===3){
    //   Speak("すごくいいかんじだね",index);
    // }
    // else if(index===4){
    //   Speak("もっとがんばりなよ",index);
    // }
    // else if(index===5){
    //   Speak("ちょっと休憩しようね",index);
    // }
    // else{
    //   Speak("えらい、よくがんばってるね",index);
    // }
    let today:Date = new Date();
    let year:number = today.getFullYear();
    let month:number = today.getMonth()+1;
    let month2:string = month<10 ? "0"+month.toString() : month.toString();
    let day:number = today.getDate();
    let day2:string = day<10 ? "0"+day.toString() : day.toString();
    let hour:number = today.getHours();
    let hour2:string = hour<10 ? "0"+hour.toString() : hour.toString();
    let minute:number = today.getMinutes();
    let minute2:string = minute<10 ? "0"+minute.toString() : minute.toString();
    let second:number = today.getSeconds();
    let second2:string = second<10 ? "0"+second.toString() : second.toString();
    let date:string = `${year}年${month2}月${day2}日`;
    let time:string = `${hour2}時${minute2}分${second2}秒`;
    let data = {
      emojiIndex: index,
      time: time,
    }
    //新しいFirebaseの処理--------------------------------------------
    let successNum:number = 0;
    //全体用のドキュメントに保存--------------------------
    if(9<=hour && hour<=18){
      try{
        await updateDoc(doc(db,"all","#all"),{[`${hour2}_${index}`]: increment(1)})
        console.log("データベースに保存しました")
        successNum += 1
      }catch (e) {
          console.log("エラー",e)
      }
    }
    //------------------------------------------------
    //今日の日付のドキュメントに保存-----------------------
    if(9<=hour && hour<=18){
      try{
        const docRef = doc(db, "all", date);
        const todayDoc = await getDoc(docRef);
        if(todayDoc.exists()){
          await updateDoc(docRef,{[`${hour2}_${index}`]: increment(1)})
        }
        else{
          await setDoc(docRef,{"09_1":0,"09_2":0,"09_3":0,"09_4":0,"09_5":0,"09_6":0,"10_1":0,"10_2":0,"10_3":0,"10_4":0,"10_5":0,"10_6":0,"11_1":0,"11_2":0,"11_3":0,"11_4":0,"11_5":0,"11_6":0,"12_1":0,"12_2":0,"12_3":0,"12_4":0,"12_5":0,"12_6":0,"13_1":0,"13_2":0,"13_3":0,"13_4":0,"13_5":0,"13_6":0,"14_1":0,"14_2":0,"14_3":0,"14_4":0,"14_5":0,"14_6":0,"15_1":0,"15_2":0,"15_3":0,"15_4":0,"15_5":0,"15_6":0,"16_1":0,"16_2":0,"16_3":0,"16_4":0,"16_5":0,"16_6":0,"17_1":0,"17_2":0,"17_3":0,"17_4":0,"17_5":0,"17_6":0,"18_1":0,"18_2":0,"18_3":0,"18_4":0,"18_5":0,"18_6":0});
          await updateDoc(docRef,{[`${hour2}_${index}`]: increment(1)})
        }
        console.log("データベースに保存しました")
        successNum += 1
      }catch (e) {
          console.log("エラー",e)
      }
    }
    //-----------------------------------------------
    //今日の日付のドキュメントにデータを保存----------------
    try{
      await setDoc(doc(collection(db,"all",date,"data")),data)
      console.log("データベースに保存しました")
      successNum += 1
    }catch (e) {
      console.log("エラー",e)
    }
    //----------------------------------------------
    //グラフを更新------------------------------------
    if(9<=hour && hour<=18 && (selectDocument==="#all" || selectDocument===date)){
      chartData[hour-9][index-1] += 1
      pieChartData[index-1] += 1
    }
    //----------------------------------------------
    //エラーを確認
    if((successNum===3) || ((9>hour || hour>18)&&(successNum===1))){
      setOpenMsg(true);
    }
    else{
      alert("エラーが起こりました")
    }
    //------------------------------------------------
  }

  //メニューボタンの処理
  const AllHandleClose = () => {
    setAppBarText("全てのデータ");
    menuHandleClose();
    setSelectDocument("#all");
    CreateChart("#all");
    // CreateLineChart("#all");
    // CreatePieChart("#all");
  }
  const TodayHandleClose = () => {
    setAppBarText("今日のデータ");
    menuHandleClose();
    let today:Date = new Date();
    let year:number = today.getFullYear();
    let month:number = today.getMonth()+1;
    let month2:string = month<10 ? "0"+month.toString() : month.toString();
    let day:number = today.getDate();
    let day2:string = day<10 ? "0"+day.toString() : day.toString();
    let date:string = `${year}年${month2}月${day2}日`;
    setSelectDocument(date);
    CreateChart(date);
    // CreateLineChart(date);
    // CreatePieChart(date);
  }
  const CalenderHandleClose = () => {
    menuHandleClose();
    modalHandleOpen();
  }

  //calenderbuttonの処理
  const calenderCloseBtn = () => {
    modalHandleClose();
  }
  const calenderOkBtn = () => {
    modalHandleClose();
    let calenderDate = calenderValue?.toDate();
    let displayDate = ""
    let docName = ""
    if(calenderDate){
      displayDate = `${calenderDate.getFullYear()}年${calenderDate.getMonth()+1}月${calenderDate.getDate()}日`;
      let cyear:number = calenderDate.getFullYear();
      let cmonth:number = calenderDate.getMonth()+1;
      let cmonth2:string = cmonth<10 ? "0"+cmonth.toString() : cmonth.toString();
      let cday:number = calenderDate.getDate();
      let cday2:string = cday<10 ? "0"+cday.toString() : cday.toString();
      docName = `${cyear}年${cmonth2}月${cday2}日`;
      let today:Date = new Date();
      if(`${today.getFullYear()}年${today.getMonth()+1}月${today.getDate()}日`===displayDate){
        displayDate = "今日";
      }
      else{
        displayDate = docName;
      }
      setAppBarText(`${displayDate}のデータ`);
      setSelectDocument(docName);
      CreateChart(docName);
    }
    else{
      setAppBarText("エラー");
    }
  }

  //円グラフ
  const pieData = {
    labels: ['🥰', '😉', '🙂', '😐', '😔', '😰'],
    datasets: [
      {
        label: '# of Votes',
        data: [pieChartData[0]/SumList(pieChartData)*100,pieChartData[1]/SumList(pieChartData)*100,pieChartData[2]/SumList(pieChartData)*100,pieChartData[3]/SumList(pieChartData)*100,pieChartData[4]/SumList(pieChartData)*100,pieChartData[5]/SumList(pieChartData)*100],
        backgroundColor: [
          'rgba(232, 51, 19, 0.88)',
          'rgba(232, 143, 19, 0.88)',
          'rgba(225, 232, 19, 0.88)',
          'rgba(19, 232, 40, 0.88)',
          'rgba(35, 194, 252, 0.88)',
          'rgba(57, 35, 252, 0.88)',
        ],
        borderColor: [
          'rgba(232, 51, 19, 1)',
          'rgba(232, 143, 19, 1)',
          'rgba(225, 232, 19, 1)',
          'rgba(19, 232, 40, 1)',
          'rgba(35, 194, 252, 1)',
          'rgba(57, 35, 252, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: true
      },
      title: {
        display: true,
        text: '絵文字の割合',
        position: "bottom" as const,
      },
    },
  };

  const changeLineChart = () => {
    console.log("折れ線グラフ")
    setLineChartBool(true)
  }
  const changePieChart = async() => {
    console.log("円グラフ")
    setLineChartBool(false);
  }
  const CreatePieChart = async(chartDocData:DocumentData) =>{
    let rateData = [0,0,0,0,0,0]
    for(let i=0; i<Object.keys(chartDocData).length;i++){
      let n = Object.keys(chartDocData)[i].slice(-1);
      rateData[Number(n)-1] += chartDocData[Object.keys(chartDocData)[i]]
    }
    setPieDB(chartDocData)
    setBtnsState(["outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","contained"])
    setPieChartData(rateData);
  }

  const ChangePieChart = (index:number) => {
    let state = ["outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined","outlined"]
    state[index] = "contained"
    setBtnsState(state)
    if(index===10){
      let rateData = [0,0,0,0,0,0]
      if(pieDB){
        for(let i=0; i<Object.keys(pieDB).length;i++){
          let n = Object.keys(pieDB)[i].slice(-1);
          rateData[Number(n)-1] += pieDB[Object.keys(pieDB)[i]]
        }
      }
      setPieChartData(rateData);
    }
    else{
      let rateData = [0,0,0,0,0,0]
      if(pieDB){
        for(let i=0; i<Object.keys(pieDB).length;i++){
          let timeKey = `${index+9}`;
          timeKey = timeKey==="9" ? "09" : timeKey;
          if(Object.keys(pieDB)[i].slice(0,2)===`${timeKey}`){
            let n = Object.keys(pieDB)[i].slice(-1);
            rateData[Number(n)-1] += pieDB[Object.keys(pieDB)[i]]
          }
        }
      }
      setPieChartData(rateData);
    }
  }

  return (
    <div id="screen">
      <div id="main">
        <div id="QuestionText">今の気持ちに最も合う絵文字を選んでね</div>
        <table id="btnTable">
          <tbody>
            <tr>
              <td><img style={imageStyle1} className="emojiBtn" id="emoji1" src={emoji1} onClick={() => btnOpacity1===1.0 ? SendData(1) : console.log("クリック無効")}></img></td>
              <td><img style={imageStyle2} className="emojiBtn" id="emoji2" src={emoji2} onClick={() => btnOpacity2===1.0 ? SendData(2) : console.log("クリック無効")}></img></td>
              <td><img style={imageStyle3} className="emojiBtn" id="emoji3" src={emoji3} onClick={() => btnOpacity3===1.0 ? SendData(3) : console.log("クリック無効")}></img></td>
              <td><img style={imageStyle4} className="emojiBtn" id="emoji4" src={emoji4} onClick={() => btnOpacity4===1.0 ? SendData(4) : console.log("クリック無効")}></img></td>
              <td><img style={imageStyle5} className="emojiBtn" id="emoji5" src={emoji5} onClick={() => btnOpacity5===1.0 ? SendData(5) : console.log("クリック無効")}></img></td>
              <td><img style={imageStyle6} className="emojiBtn" id="emoji6" src={emoji6} onClick={() => btnOpacity6===1.0 ? SendData(6) : console.log("クリック無効")}></img></td>
            </tr>
          </tbody>
        </table>
        <div id="bar">
        <Box sx={{ flexGrow: 1}}>
          <ThemeProvider theme={theme}>
            <AppBar position="static" color="primary" enableColorOnDark={false}>
              <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} aria-controls={menuOpen ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={menuOpen ? 'true' : undefined} onClick={menuHandleClick}>
                  <MenuIcon />
                </IconButton>
                <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={menuHandleClose} MenuListProps={{'aria-labelledby': 'basic-button',}}>
                  <MenuItem onClick={AllHandleClose}>全てのデータ</MenuItem>
                  <MenuItem onClick={TodayHandleClose}>今日のデータ</MenuItem>
                  <MenuItem onClick={CalenderHandleClose}>カレンダーから選択</MenuItem>
                </Menu>
                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                  {appBarText}
                </Typography>
                <Button variant="contained" endIcon={<SsidChartIcon/>} sx={{ mr: 1 }} onClick={changeLineChart}>面グラフ</Button>
                <Button variant="contained" endIcon={<PieChartIcon/>} onClick={changePieChart}>円グラフ</Button>
              </Toolbar>
            </AppBar>
          </ThemeProvider>
        </Box>
        </div>
        <div id="chartDiv">
          {lineChartBool ? (<Line options={options} data={data}/>) : 
          (
            <div id="pieChartDiv">
              <div id="test1">
                <Pie options={pieOptions} data={pieData} id="pie"/>
              </div>
              <div id="test2">
                <Stack spacing={0.3} direction="column" id="btns">
                  <Button variant={btnsState[0]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(0)}>9時</Button>
                  <Button variant={btnsState[1]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(1)}>10時</Button>
                  <Button variant={btnsState[2]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(2)}>11時</Button>
                  <Button variant={btnsState[3]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(3)}>12時</Button>
                  <Button variant={btnsState[4]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(4)}>13時</Button>
                  <Button variant={btnsState[5]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(5)}>14時</Button>
                  <Button variant={btnsState[6]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(6)}>15時</Button>
                  <Button variant={btnsState[7]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(7)}>16時</Button>
                  <Button variant={btnsState[8]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(8)}>17時</Button>
                  <Button variant={btnsState[9]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(9)}>18時</Button>
                  <Button variant={btnsState[10]==="outlined" ? "outlined" : "contained"} onClick={() => ChangePieChart(10)}>全体</Button>
                </Stack>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal open={modalOpen} onClose={modalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <StaticDatePicker openTo="day" value={calenderValue} shouldDisableDate={isWeekend} orientation={"portrait"} onChange={(newValue) => {calenderSetValue(newValue);}} renderInput={(params) => <TextField {...params} />} componentsProps={{actionBar: {actions: undefined}}}/>
          </LocalizationProvider>
          <div id="modalBtn">
            <Button variant="outlined" sx={{ mr: 3 }} size="large" onClick={calenderCloseBtn}>閉じる</Button>
            <Button variant="outlined" sx={{ ml: 3 }} size="large" onClick={calenderOkBtn}>決定</Button>
          </div>
        </Box>
      </Modal>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center',}} open={openMsg} autoHideDuration={2000} onClose={handleCloseMsg}>
        <Alert onClose={handleCloseMsg} severity="success" sx={{ width: '100%' }}>
          データベースに保存しました！
        </Alert>
      </Snackbar>
    </div>
  );
}
export default App;

//前のFirebase処理------------------------------------
    // try{
    //     const docC = await setDoc(doc(collection(db,date)),data)
    //     console.log("データベースに保存しました")
    //     if(9<=hour && hour<=19){
    //       let changeData = chartData;
    //       changeData[hour-9][index-1] += 1;
    //       setChartData(changeData);
    //     }
    //     setBtnOpacity(0.3)
    //     setTimeout(() => {setBtnOpacity(1.0)}, 2000)
    //     setOpenMsg(true)
    // }catch (e) {
    //     console.log("エラー",e)
    //     alert("エラーが起こりました")
    // }
    //-----------------------------------------------


    // const pieDoc = await getDoc(doc(db, "all", docName));
    // const pieDocData = pieDoc.data();
        // }
    // let rateSum = rateData.reduce((a, b) => {return a + b;});
    // let rateData2 = rateData.map(function (d) {
    //   return d/rateSum*100
    // })