import {FirebaseApp, initializeApp } from "firebase/app";
import {Firestore, getFirestore } from "firebase/firestore/lite";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_YOUR_API,
    authDomain: "emojiboardapp.firebaseapp.com",
    projectId: "emojiboardapp",
};
const app:FirebaseApp =  initializeApp(firebaseConfig);
const db:Firestore = getFirestore(app);

export default db;